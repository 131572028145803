import { courseById } from 'API/course'

import React, { useState } from 'react'
import { useEffect } from 'react'
import { CourseType } from 'types/CourseAdditionMetaData'

export const useCourseHook = (_id: string) => {
	const [id, setId] = useState<string>()
	const [error, setError] = useState<string>()

	const [course, setCourse] = useState<CourseType>()
	useEffect(() => {
		if (_id) {
			setId(_id)
		}
	}, [])

	useEffect(() => {
		const run = async () => {
			if (id) {
				const course = await courseById(id)
				if (course) {
					setCourse(course)
					setError(undefined)
				} else {
					setError('Failed to fetch')
				}
			}
		}
		run()
	}, [id])

	const CourseDisplay = (
		<>
			{course && (
				<>
					<h1>{course.name}</h1>

					<h2>Description: {course.description}</h2>
				</>
			)}
		</>
	)

	const CourseError = (
		<>
			{error && (
				<>
					<h2 style={{ color: 'red' }}>Failed to fetch Course </h2>
				</>
			)}
		</>
	)

	return { course, setCourse, error, setError, id, setId, CourseDisplay, CourseError }
}
