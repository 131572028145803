import { addGroupOfUsers } from 'API/course'
import { listGroups } from 'API/group'
import { navigate, PageProps } from 'gatsby'
import { useCourseHook } from 'hooks/useCourseHook'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

const CourseAddGroup = (props: PageProps) => {
	const { CourseDisplay, CourseError, course } = useCourseHook(props.params.id)
	const [groups, setGroups] = useState<string[]>()
	const [check, setCheck] = useState(false)

	useEffect(() => {
		const run = async () => {
			if (course) {
				const groups = await listGroups()
				if (groups) {
					setGroups(groups.map((group) => group.name))
				}
			}
		}
		run()
	}, [course])

	const handleClick = async (name: string) => {
		if (check) {
			toast('Already Processing', { type: 'error' })
		} else {
			setCheck(true)
			try {
				if (course) {
					await addGroupOfUsers(name, course.uid)
					navigate(`/admin/course/${course.uid}`)
				} else {
					toast('Error')
				}
				setCheck(false)
			} catch (err) {
				setCheck(false)
			}
		}
	}

	return (
		<>
			{CourseDisplay}
			<p>Add Group as Students</p>
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Group Name</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					{groups?.map((elem, index) => {
						return (
							<>
								<tr>
									<td scope='row'>{index + 1}</td>
									<td>{elem}</td>
									<td>
										<Button onClick={() => handleClick(elem)} variant='primary'>
											Add Group To Course
										</Button>
									</td>
								</tr>
							</>
						)
					})}
				</tbody>
			</table>
			{CourseError}
		</>
	)
}

export default CourseAddGroup
